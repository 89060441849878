import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import { useLocation } from "@reach/router";
import "../assets/style/globalStyle.scss";
import arrowleft from "../assets/img/arrow_left.png";
import arrowright from "../assets/img/arrow_right.png";
import GooglePlay from "../assets/img/googleplay.webp";
import appstore from "../assets/img/AppStore.png";
import stats from "../assets/img/stats.webp";
import trophy from "../assets/img/trophy.png";
import terrain from "../assets/img/terrain.png";
import basketball from "../assets/img/basket-ball.png";
import calendar from "../assets/img/calendar.png";
import gabdou from "../assets/img/gabdou.webp";
import anoureth from "../assets/img/anoureth.jpeg";
import eventVideo from "../assets/img/event.mp4";
import backgroundImage from "../assets/img/background_1.webp";
import NavBar from "../components/navBarSite";
import Footer from "../components/footer";
import TeamCard from "../components/teamCard";
import OpinionCard from "../components/opinionCard";
import ArticleCard from "../components/articleCard";
import { SEO } from "../components/seo";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { graphql } from "gatsby";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { countAllPlaygrounds } from "../services/playgroundService";
import { countAllEvents } from "../services/eventService";
import { countAllUsers } from "../services/authService";
import { countAllGames } from "../services/gameService";
import { getAllArticles } from "../services/articleService";

import "react-medium-image-zoom/dist/styles.css";
import { StaticImage } from "gatsby-plugin-image";
import { Button } from "@mui/material";

export function useMedia(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    media.addEventListener("change", listener);
    return () => media.removeEventListener("change", listener);
  }, [matches, query]);

  return matches;
}

export default function Home({ data }) {
  const [noRedirect, setNoRedirect] = useState(false);
  const path = new URLSearchParams(useLocation().search).get("p");
  const query = new URLSearchParams(useLocation().search).get("q");
  const [countPlaygrounds, setCountPlaygrounds] = useState([]);
  const [countEvents, setCountEvents] = useState([]);
  const [countUsers, setCountUsers] = useState([]);
  const [countGames, setCountGames] = useState([]);
  const [articles, setArticles] = useState([]);
  // const { posts } = data.blog;

  const isPhone = useMedia("(max-width: 767px)");
  const isTablet = useMedia("(min-width: 768px) and (max-width: 1224px)");
  const isLaptop = useMedia("(min-width: 1225px)");

  // const getAllArticlesToModerate = async () => {
  //   const data = await getAllArticles();

  //   if (!data) {
  //     return (
  //       <div className="container is-fullhd">
  //         <h1>Pas d'articles en cours</h1>
  //       </div>
  //     );
  //   }

  //   setArticles(data);
  // };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 2,
    },
    laptop: {
      breakpoint: { max: 1700, min: 1275 },
      items: 3,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1275, min: 850 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 850, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const responsiveTeam = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 2,
    },
    laptop: {
      breakpoint: { max: 1500, min: 1100 },
      items: 3,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1100, min: 850 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 850, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const countAllValidatedPlaygrounds = async () => {
    const data = await countAllPlaygrounds();

    if (!data) {
      return (
        <div className="container is-fullhd" style={{ width: "880px" }}>
          <h1>OOOOOOOOHHHHHHHH DAMN</h1>
        </div>
      );
    }

    setCountPlaygrounds(data);
  };

  const countAllUsersApp = async () => {
    const data = await countAllUsers();

    if (!data) {
      return (
        <div className="container is-fullhd" style={{ width: "880px" }}>
          <h1>OOOOOOOOHHHHHHHH DAMN</h1>
        </div>
      );
    }

    setCountUsers(data);
  };

  const countAllValidatedEvents = async () => {
    const data = await countAllEvents();

    if (!data) {
      return (
        <div className="container is-fullhd" style={{ width: "880px" }}>
          <h1>Pas d'évènements en cours</h1>
        </div>
      );
    }

    setCountEvents(data);
  };

  const countallGames = async () => {
    const data = await countAllGames();

    if (!data) {
      return (
        <div className="container is-fullhd" style={{ width: "880px" }}>
          <h1>OOOOOOOOHHHHHHHH DAMN</h1>
        </div>
      );
    }

    setCountGames(data);
  };

  useEffect(() => {
    if (path) {
      if (query) navigate(`/app/redirect?p=${path}&q=${query}`);
      else navigate(`/app/redirect?p=${path}`);
    } else {
      setNoRedirect(true);
    }
  }, []);

  useEffect(() => {
    countAllValidatedPlaygrounds();
    countAllValidatedEvents();
    countAllUsersApp();
    countallGames();
    // getAllArticlesToModerate();
  }, []);

  const styles = {
    container: {
      position: "relative",
      margin: 0,
      padding: 0,
      fontFamily: "Montserrat",
      width: "100%",
    },
    background: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "auto",
      marginTop: 73,
      padding: isPhone ? "45px 25px 0px 25px" : "75px 104px 0px 104px",
    },
    backgroundHeader: {
      display: "flex",
      flexDirection: "column",
      backgroundSize: "cover",
      backgroundImage: `linear-gradient(180deg, rgba(216,94,75, 0.5),
      rgba(161,84,119, 0.5)),url(${backgroundImage})`,
      height: isPhone ? 500 : "100vh",
      backgroundPosition: "center",
      marginTop: 73,
      padding: isPhone ? "45px 0px 0px 27px" : "200px 0px 0px 104px",
    },
    appCircles: {
      minHeight: 56,
      minWidth: 56,
      maxWidth: 56,
      maxHeight: 56,
      borderRadius: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    statsSection: {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-around",
      flexDirection: "column",
      textAlign: "center",
      backgroundSize: "cover",
      backgroundImage: `linear-gradient(180deg, rgba(216,94,75, 0.5),
      rgba(161,84,119, 0.5)),url(${stats})`,
      height: "auto",
      backgroundPosition: "center",
      padding: 30,
      marginBottom: 70,
      marginTop: 70,
    },
    appFonctionalities: {
      display: "flex",
      flexDirection: "row",
    },
    appTitles: {
      color: "black",
      fontSize: "16px",
      fontWeight: 800,
      marginLeft: 12,
    },
    appContent: {
      color: "#6D6D6D",
      marginTop: 7,
      marginLeft: 12,
      width: isPhone ? 300 : 240,
      marginBottom: isPhone ? 30 : 55,
      marginRight: isPhone ? 0 : 45,
    },
    statsSectionMobile: {
      display: "flex",
      justifyContent: "space-around",
      flexDirection: "column",
      textAlign: "center",
      backgroundSize: "cover",
      backgroundImage: `linear-gradient(180deg, rgba(216,94,75, 0.5),
      rgba(161,84,119, 0.5)),url(${stats})`,
      height: "auto",
      backgroundPosition: "center",
      marginBottom: 140,
      marginTop: 140,
    },
    columnSections: {
      display: "flex",
      flexDirection: "column",
    },
    circle: {
      backgroundImage: `linear-gradient(180deg, #D85E4B, #A15477)`,
      borderRadius: "100%",
      width: 950,
      height: 950,
      marginTop: 0,
      marginLeft: -250,
    },
    circleLaptop: {
      backgroundImage: `linear-gradient(180deg, #D85E4B, #A15477)`,
      borderRadius: "100%",
      width: 700,
      height: 700,
      marginTop: 111,
      marginLeft: -250,
    },
    circleMobile: {
      backgroundImage: `linear-gradient(180deg, #D85E4B, #A15477)`,
      borderRadius: "100%",
      width: 400,
      height: 400,
      marginTop: 100,
      marginLeft: -150,
    },
    circleTablet: {
      backgroundImage: `linear-gradient(180deg, #D85E4B, #A15477)`,
      borderRadius: "100%",
      width: 500,
      height: 500,
      marginTop: 250,
      marginLeft: "auto",
      marginRight: "auto",
    },
    circle2: {
      backgroundImage: `linear-gradient(180deg, #D85E4B, #A15477)`,
      borderRadius: "100%",
      width: 680,
      height: 680,
      zIndex: -1,
      marginTop: -60,
      marginLeft: 1550,
    },
    circle2Mobile: {
      backgroundImage: `linear-gradient(180deg, #D85E4B, #A15477)`,
      borderRadius: "100%",
      width: 550,
      height: 550,
      marginTop: 230,
      marginLeft: 200,
    },
    circle3: {
      backgroundImage: `linear-gradient(180deg, #D85E4B, #A15477)`,
      borderRadius: "100%",
      width: 800,
      height: 800,
      marginLeft: -150,
      marginBottom: 20,
    },
    circle3Mobile: {
      backgroundImage: `linear-gradient(180deg, #D85E4B, #A15477)`,
      borderRadius: "100%",
      width: 450,
      height: 450,
      marginTop: 115,
      marginLeft: -280,
    },
    circle3Tablet: {
      backgroundImage: `linear-gradient(180deg, #D85E4B, #A15477)`,
      borderRadius: "100%",
      width: 550,
      height: 550,
      marginTop: 85,
      marginLeft: "auto",
      marginRight: "auto",
    },
    circle3Laptop: {
      backgroundImage: `linear-gradient(180deg, #D85E4B, #A15477)`,
      borderRadius: "100%",
      width: 650,
      height: 650,
      marginTop: 50,
      marginLeft: -300,
    },
    appSection: {
      display: "flex",
      flexDirection: isPhone ? "column" : "row",
      padding: isPhone ? "0px 18px 0px 18px" : "0px 60px 0px 95px",
      justifyContent: "space-around",
      marginTop: isPhone ? 35 : 140,
    },
    statInfo: {
      fontSize: isPhone ? "48px" : "64px",
      color: "white",
      fontWeight: "bold",
    },
    statTitle: {
      fontSize: isPhone ? "15px" : "24px",
      color: "white",
      marginTop: isPhone ? "-10px" : "10px",
      fontWeight: 400,
    },
    defaultDot: {
      backgroundColor: "#D35D50",
      width: 38,
      height: 8,
      borderRadius: "4px",
      opacity: "0.28",
      marginLeft: 5,
      marginRight: 5,
      border: 0,
    },
    activeDot: {
      backgroundColor: "#D35D50",
      width: 54,
      height: 8,
      borderRadius: "4px",
      marginLeft: 5,
      marginRight: 5,
      border: 0,
    },
    rightArrow: {
      width: 47,
      height: 47,
      backgroundColor: "white",
      padding: 15,
      boxShadow: "0px 1px 21px 6px rgba(0, 0, 0, 0.08)",
    },
    sectionTitles: {
      fontWeight: 600,
      fontSize: "18px",
      color: "#F56D44",
    },
    sectionSubTitle: {
      fontWeight: 800,
      fontSize: isPhone ? "24px" : "48px",
      color: "black",
    },
    ytbBouton: {
      fontSize: "14px",
      fontWeight: 700,
      color: "white",
      backgroundImage: `linear-gradient(90deg, #D85E4B, #A15477)`,
      borderRadius: 25,
      alignItems: "center",
      textAlign: "center",
      paddingTop: 14,
      paddingBottom: 14,
      marginTop: 30,
      marginBottom: isPhone ? 37 : 150,
      width: isPhone ? "100%" : 279,
      alignSelf: "center",
    },
    btnArrows: {
      width: "47px",
      height: "47px",
      backgroundColor: "white",
      boxShadow: "0px 1px 19px -5px rgba(0, 0, 0, 0.1)",
      marginLeft: 10,
      padding: 14,
    },
    btnGroup: {
      overflow: "hidden",
      marginLeft: 1655,
      marginTop: -540,
      display: "flex",
      flexDirection: "row",
      zIndex: 1,
    },
    bnCoachingNb: {
      fontSize: "36px",
      fontWeight: 800,
      color: "#F56D44",
    },
    bnCoachingInfo: {
      fontWeight: 400,
      color: "black",
    },
  };

  const CustomDot = ({ index, onClick, active }) => {
    return (
      <li
        onClick={() => onClick()}
        style={{
          ...(active ? styles.activeDot : styles.defaultDot),
          cursor: "pointer",
        }}
      >
        {React.Children.toArray()[index]}
      </li>
    );
  };

  const ButtonGroup = ({ next, previous }) => {
    return (
      <div style={styles.btnGroup}>
        <Button style={styles.btnArrows} onClick={() => previous()}>
          <img
            src={arrowleft}
            alt="arrow"
            style={{ width: "16px", alignSelf: "center" }}
          ></img>
        </Button>
        <Button style={styles.btnArrows} onClick={() => next()}>
          <img
            src={arrowright}
            alt="arrow"
            style={{ width: "16px", alignSelf: "center" }}
          ></img>
        </Button>
      </div>
    );
  };

  return (
    <div style={styles.container}>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1"
      ></meta>
      <html lang="fr"></html>
      <NavBar></NavBar>
      {/* BACKGROUND */}
      <div style={styles.backgroundHeader}>
        <h1
          style={{
            fontWeight: "800",
            fontSize: isPhone ? "42px" : "70px",
            color: "white",
          }}
        >
          Ball'N Connect
        </h1>
        <h2
          style={{
            fontWeight: "700",
            fontSize: isPhone ? "28px" : "64px",
            color: "white",
            marginBottom: "10px",
          }}
        >
          {isPhone ? (
            <> Le plus grand club de basketball digital au monde</>
          ) : (
            <>
              Le plus grand club de <br /> basketball digital au monde
            </>
          )}
        </h2>
        <h3
          style={{
            fontWeight: "700",
            fontSize: isPhone ? "14px" : "32px",
            color: "white",
            marginBottom: "10px",
          }}
        >
          FIND, PLAY, PROGRESS
        </h3>
        <div>
          <a href="https://play.google.com/store/apps/details?id=com.ballnconnect.ballnconnect&hl=fr&gl=US">
            <img
              src={GooglePlay}
              alt="googleplay"
              style={{
                width: isPhone ? 145 : 236,
                marginTop: 20,
                marginRight: 20,
              }}
            ></img>
          </a>
          <a href="https://apps.apple.com/fr/app/balln-connect/id1534174248">
            <img
              src={appstore}
              alt="appstore"
              style={{ width: isPhone ? 145 : 236 }}
            ></img>
          </a>
        </div>
      </div>

      {/* APP STATS */}
      <div style={!isPhone ? styles.statsSection : styles.statsSectionMobile}>
        <h2
          style={{
            fontWeight: 700,
            fontSize: isPhone ? "24px" : "48px",
            color: "white",
          }}
        >
          Quelques chiffres sur Ball'N Connect
        </h2>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
          }}
        >
          {isPhone ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                flexDirection: "column",
              }}
            >
              <div style={{ width: 150 }}>
                <p style={styles.statInfo}>
                  {countUsers.countAllUsers !== undefined ||
                  countUsers.countAllUsers > 10000
                    ? countUsers.countAllUsers.toString().substring(0, 2) + " K"
                    : 0}
                </p>
                <p style={styles.statTitle}>Utilisateurs</p>
              </div>
              <div style={{ width: 150 }}>
                <p style={styles.statInfo}>4112</p>
                <p style={styles.statTitle}>Terrains</p>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                width: "100%",
              }}
            >
              <div>
                <p style={styles.statInfo}>
                  {countUsers.countAllUsers !== undefined ||
                  countUsers.countAllUsers > 10000
                    ? countUsers.countAllUsers.toString().substring(0, 2) + " K"
                    : 0}
                  {/* 28 K */}
                </p>
                <p style={styles.statTitle}>Utilisateurs</p>
              </div>
              <div>
                <p style={styles.statInfo}>4112</p>
                <p style={styles.statTitle}>Terrains</p>
              </div>
            </div>
          )}
          {isPhone ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                flexDirection: "column",
              }}
            >
              <div style={{ width: 150 }}>
                <p style={styles.statInfo}>
                  {countEvents.countEvents === undefined
                    ? 0
                    : countEvents.countEvents}
                  {/* 45 */}
                </p>
                <p style={styles.statTitle}>Évènements organisés</p>
              </div>
              <div style={{ width: 150 }}>
                <p style={styles.statInfo}>3823</p>
                <p style={styles.statTitle}>Matchs créés</p>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                width: "100%",
              }}
            >
              <div>
                <p style={styles.statInfo}>
                  {countEvents.countEvents === undefined
                    ? 0
                    : countEvents.countEvents}
                  {/* 45 */}
                </p>
                <p style={styles.statTitle}>Évènements organisés</p>
              </div>
              <div>
                <p style={styles.statInfo}>
                  3823
                  {/* 1000 */}
                </p>
                <p style={styles.statTitle}>Matchs créés</p>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* PRESENTATION APP */}
      {/* {isLaptop && (
        <div
          style={{
            overflow: "hidden",
            width: "100%",
            position: "absolute",
            zIndex: -1,
          }}
        >
          <div style={styles.circleLaptop}></div>
        </div>
      )} */}
      {isTablet && (
        <div
          style={{
            overflow: "hidden",
            width: "100%",
            position: "absolute",
            zIndex: -1,
          }}
        >
          <div style={styles.circleTablet}></div>
        </div>
      )}
      {isPhone && (
        <div
          style={{
            overflow: "hidden",
            width: "100%",
            position: "absolute",
            zIndex: -1,
          }}
        >
          <div style={styles.circleMobile}></div>
        </div>
      )}
      {/* {!isPhone && !isTablet && !isLaptop && (
        <div
          style={{
            overflow: "hidden",
            width: "100%",
            position: "absolute",
            zIndex: -1,
          }}
        >
          <div style={styles.circle}></div>
        </div>
      )} */}
      <div style={{ ...styles.appSection }}>
        {isPhone && (
          <>
            <p style={styles.sectionTitles}>La solution</p>
            <h2 style={styles.sectionSubTitle}>
              L'application des basketteurs
            </h2>
          </>
        )}
        {!isTablet && !isLaptop && (
          <div style={{ ...styles.columnSections, overflow: "hidden" }}>
            <StaticImage
              src="../assets/img/3.webp"
              style={{
                alignSelf: "center",
                width: isPhone ? "auto" : "auto",
                maxWidth: 350,
                height: "auto",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              quality={100}
              alt="phone screen"
            />
          </div>
        )}
        <div
          style={{ ...styles.columnSections, width: isPhone ? "100%" : 700 }}
        >
          {!isPhone && (
            <>
              <p style={styles.sectionTitles}>La solution</p>
              <h2 style={styles.sectionSubTitle}>
                L'application des basketteurs
              </h2>
            </>
          )}
          {isTablet && (
            <>
              {" "}
              <div style={styles.columnSections}>
                <StaticImage
                  src="../assets/img/3.webp"
                  style={{
                    alignSelf: "center",
                    width: 350,
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 50,
                  }}
                  quality={100}
                  alt="phone screen"
                />
              </div>
            </>
          )}
          {isPhone ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 30,
                marginTop: 50,
                marginBottom: 50,
              }}
            >
              <p>
                Ball'N Connect est une application mobile qui révolutionne le
                basketball. Elle a pour mission d'améliorer la pratique, le jeu
                et les performances de plus de 14 millions de fans en France.
              </p>
              <p>
                Avec l'application Ball'N Connect, tu pourras progresser dans
                ton jeu, échanger avec les joueurs qui partagent la même
                passion.
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <p style={styles.bnCoachingNb}>01</p>
                <p style={{ ...styles.bnCoachingInfo, marginLeft: 35 }}>
                  {
                    <text
                      style={{
                        fontSize: "16px",
                        fontWeight: 700,
                        color: "black",
                      }}
                    >
                      FIND :
                    </text>
                  }{" "}
                  Géolocalise des terrains autour de soi & trouve des joueurs
                  sur les terrains.
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <p style={styles.bnCoachingNb}>02</p>
                <p style={{ ...styles.bnCoachingInfo, marginLeft: 28 }}>
                  {
                    <text
                      style={{
                        fontSize: "16px",
                        fontWeight: 700,
                        color: "black",
                      }}
                    >
                      PLAY :
                    </text>
                  }{" "}
                  Créé un match sur un terrain extérieur ou en salle payante.
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <p style={styles.bnCoachingNb}>03</p>
                <p
                  style={{
                    ...styles.bnCoachingInfo,
                    marginLeft: 28,
                  }}
                >
                  {
                    <text
                      style={{
                        fontSize: "16px",
                        fontWeight: 700,
                        color: "black",
                      }}
                    >
                      PROGRESS :
                    </text>
                  }{" "}
                  Retrouve des tutoriels vidéos réalisés par des professionnels
                  et trouve ton personal trainer
                </p>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 30,
                marginTop: 50,
                marginBottom: 50,
              }}
            >
              <p>
                Ball'N Connect est une application mobile qui révolutionne le
                basketball. Elle a pour mission d'améliorer la pratique, le jeu
                et les performances de plus de 14 millions de fans en France.
              </p>
              <p>
                Avec l'application Ball'N Connect, tu pourras progresser dans
                ton jeu, échanger avec les joueurs qui partagent la même
                passion.
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <p style={styles.bnCoachingNb}>01</p>
                <p style={{ ...styles.bnCoachingInfo, marginLeft: 35 }}>
                  {
                    <text
                      style={{
                        fontSize: "16px",
                        fontWeight: 700,
                        color: "black",
                      }}
                    >
                      FIND :
                    </text>
                  }{" "}
                  Géolocalise des terrains autour de soi & trouve des joueurs
                  sur les terrains.
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <p style={styles.bnCoachingNb}>02</p>
                <p style={{ ...styles.bnCoachingInfo, marginLeft: 28 }}>
                  {
                    <text
                      style={{
                        fontSize: "16px",
                        fontWeight: 700,
                        color: "black",
                      }}
                    >
                      PLAY :
                    </text>
                  }{" "}
                  Créé un match sur un terrain extérieur ou en salle payante.
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <p style={styles.bnCoachingNb}>03</p>
                <p
                  style={{
                    ...styles.bnCoachingInfo,
                    marginLeft: 28,
                  }}
                >
                  {
                    <text
                      style={{
                        fontSize: "16px",
                        fontWeight: 700,
                        color: "black",
                      }}
                    >
                      PROGRESS :
                    </text>
                  }{" "}
                  Retrouve des tutoriels vidéos réalisés par des professionnels
                  et trouve ton personal trainer
                </p>
              </div>
            </div>
          )}
          <div
            style={{
              alignSelf: isPhone ? "center" : "",
              display: "flex",
              flexDirection: "row",
              width: "100%",
              marginTop: 20,
              marginBottom: isPhone ? 50 : 0,
            }}
          >
            <a href="https://play.google.com/store/apps/details?id=com.ballnconnect.ballnconnect&hl=fr&gl=US">
              <img
                src={GooglePlay}
                alt="googleplay"
                style={{ width: isPhone ? 145 : 236, marginRight: 38 }}
              ></img>
            </a>
            <a href="https://apps.apple.com/fr/app/balln-connect/id1534174248">
              <img
                src={appstore}
                alt="appstore"
                style={{ width: isPhone ? 145 : 236 }}
              ></img>
            </a>
          </div>
        </div>
        {!isTablet && !isPhone && (
          <div style={{ ...styles.columnSections, overflow: "hidden" }}>
            <StaticImage
              src="../assets/img/3.webp"
              style={{
                alignSelf: "center",
                width: isPhone ? "auto" : "auto",
                maxWidth: 500,
                height: "auto",
                marginLeft: "auto",
                marginTop: 50,
              }}
              quality={100}
              alt="phone screen"
            />
          </div>
        )}
      </div>

      {/* PRESENTATION APP */}
      {isLaptop && (
        <div
          style={{
            overflow: "hidden",
            width: "100%",
            position: "absolute",
            zIndex: -1,
          }}
        >
          <div style={styles.circleLaptop}></div>
        </div>
      )}
      {isTablet && (
        <div
          style={{
            overflow: "hidden",
            width: "100%",
            position: "absolute",
            zIndex: -1,
          }}
        >
          <div style={styles.circleTablet}></div>
        </div>
      )}
      {isPhone && (
        <div
          style={{
            overflow: "hidden",
            width: "100%",
            position: "absolute",
            zIndex: -1,
          }}
        >
          <div style={styles.circleMobile}></div>
        </div>
      )}
      {!isPhone && !isTablet && !isLaptop && (
        <div
          style={{
            overflow: "hidden",
            width: "100%",
            position: "absolute",
            zIndex: -1,
          }}
        >
          <div style={styles.circle}></div>
        </div>
      )}
      <div style={styles.appSection}>
        {isPhone && (
          <>
            <p style={styles.sectionTitles}>La solution</p>
            <p style={styles.sectionSubTitle}>Nos fonctionnalités</p>
          </>
        )}
        {isPhone && (
          <div style={{ ...styles.columnSections, overflow: "hidden" }}>
            <StaticImage
              src="../assets/img/5.webp"
              style={{
                alignSelf: "center",
                width: isPhone ? "auto" : "auto",
                maxWidth: 400,
                height: "auto",
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: 50,
              }}
              quality={100}
              alt="phone screen"
            />
          </div>
        )}
        {isLaptop && (
          <div style={{ ...styles.columnSections, overflow: "hidden" }}>
            <StaticImage
              src="../assets/img/5.webp"
              style={{
                alignSelf: "center",
                width: isPhone ? "auto" : "auto",
                maxWidth: 700,
                height: "auto",
                marginLeft: "auto",
              }}
              quality={100}
              alt="phone screen"
            />
          </div>
        )}
        <div
          style={{ ...styles.columnSections, width: isPhone ? "100%" : 700 }}
        >
          {!isPhone && (
            <>
              <p style={styles.sectionTitles}>La solution</p>
              <p
                style={{
                  ...styles.sectionSubTitle,
                  marginBottom: isLaptop ? 110 : 0,
                }}
              >
                Nos fonctionnalités
              </p>
            </>
          )}
          {isTablet && (
            <>
              {" "}
              <div style={styles.columnSections}>
                <StaticImage
                  src="../assets/img/5.webp"
                  style={{
                    alignSelf: "center",
                    width: isPhone ? "auto" : "auto",
                    maxWidth: 500,
                    height: "auto",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginBottom: 50,
                  }}
                  quality={100}
                  alt="phone screen"
                />
              </div>
            </>
          )}
          {isPhone ? (
            <>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ ...styles.appCircles, background: "#AE629B" }}>
                  <img
                    src={terrain}
                    style={{ width: 35, height: 35 }}
                    alt="googleplay"
                  ></img>
                </div>
                <div>
                  <p style={styles.appTitles}>Terrains</p>
                  <p style={styles.appContent}>
                    Trouve des joueurs et des terrains grâce à la
                    géolocalisation.
                  </p>
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ ...styles.appCircles, background: "#FFA02C" }}>
                  <img
                    src={trophy}
                    style={{ width: 40, height: 40 }}
                    alt="googleplay"
                  ></img>
                </div>
                <div>
                  <p style={styles.appTitles}>Tournois</p>
                  <p style={styles.appContent}>
                    Crée et/ou ajoute ton tournoi pour réunir ta communauté.
                  </p>
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ ...styles.appCircles, background: "#F8997C" }}>
                  <img
                    src={basketball}
                    style={{ width: 40, height: 40 }}
                    alt="googleplay"
                  ></img>
                </div>
                <div>
                  <p style={styles.appTitles}>Matchs</p>
                  <p style={styles.appContent}>
                    Crée tes matchs pour affronter d'autres joueurs près de toi.
                  </p>
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ ...styles.appCircles, background: "#F88B9B" }}>
                  <img
                    src={calendar}
                    style={{ width: 28, height: 29 }}
                    alt="googleplay"
                  ></img>
                </div>
                <div>
                  <p style={styles.appTitles}>Camps</p>
                  <p style={styles.appContent}>
                    Progresse, améliore ton jeu via des camps.
                  </p>
                </div>
              </div>
            </>
          ) : (
            <>
              <div style={styles.appFonctionalities}>
                <div style={{ ...styles.appCircles, background: "#AE629B" }}>
                  <img
                    src={terrain}
                    style={{ width: 35, height: 35 }}
                    alt="googleplay"
                  ></img>
                </div>
                <div>
                  <p style={styles.appTitles}>Terrains</p>
                  <p style={styles.appContent}>
                    Trouve des joueurs et des terrains grâce à la
                    géolocalisation.
                  </p>
                </div>
                <div style={{ ...styles.appCircles, background: "#FFA02C" }}>
                  <img
                    src={trophy}
                    style={{ width: 40, height: 40 }}
                    alt="googleplay"
                  ></img>
                </div>
                <div>
                  <p style={styles.appTitles}>Tournois</p>
                  <p style={styles.appContent}>
                    Crée et/ou ajoute ton tournoi pour réunir ta communauté.
                  </p>
                </div>
              </div>
              <div style={styles.appFonctionalities}>
                <div style={{ ...styles.appCircles, background: "#F8997C" }}>
                  <img
                    src={basketball}
                    style={{ width: 40, height: 40 }}
                    alt="googleplay"
                  ></img>
                </div>
                <div>
                  <p style={styles.appTitles}>Matchs</p>
                  <p style={styles.appContent}>
                    Crée tes matchs pour affronter d'autres joueurs près de toi.
                  </p>
                </div>
                <div style={{ ...styles.appCircles, background: "#F88B9B" }}>
                  <img
                    src={calendar}
                    style={{ width: 28, height: 29 }}
                    alt="googleplay"
                  ></img>
                </div>
                <div>
                  <p style={styles.appTitles}>Camps</p>
                  <p style={styles.appContent}>
                    Progresse, améliore ton jeu via des camps.
                  </p>
                </div>
              </div>
            </>
          )}
          <div
            style={{
              alignSelf: isPhone ? "center" : "",
              display: "flex",
              flexDirection: "row",
              width: "100%",
              marginTop: 20,
              marginBottom: isPhone ? 50 : 0,
            }}
          >
            <a href="https://play.google.com/store/apps/details?id=com.ballnconnect.ballnconnect&hl=fr&gl=US">
              <img
                src={GooglePlay}
                alt="googleplay"
                style={{ width: isPhone ? 145 : 236, marginRight: 38 }}
              ></img>
            </a>
            <a href="https://apps.apple.com/fr/app/balln-connect/id1534174248">
              <img
                src={appstore}
                alt="appstore"
                style={{ width: isPhone ? 145 : 236 }}
              ></img>
            </a>
          </div>
        </div>
      </div>

      {/* SECTION EVENEMENTS */}
      {isTablet ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingLeft: 18,
            paddingRight: 18,
            marginTop: 150,
          }}
        >
          <p style={styles.sectionTitles}>Nos événements</p>
          <h2 style={styles.sectionSubTitle}>Le calendrier</h2>
          <p style={{ width: "100%", marginTop: 35, textAlign: "center" }}>
            Les Boss du Playground, les Lady Boss, la Unsigned League, le Ball'N
            Street ou encore le Last Round : reste connecté sur les dates des
            tournois basketball les plus hypes de France !
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginTop: 52,
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <div style={{ width: "50%" }}>
              <video controls>
                <source src={eventVideo} type="video/mp4" />
              </video>
            </div>
          </div>
          <p
            style={{
              width: "100%",
              marginTop: 20,
              textAlign: "center",
            }}
          >
            L'été, quand la{" "}
            <span style={{ fontWeight: 700, color: "black" }}>NBA</span> et la{" "}
            <span style={{ fontWeight: 700, color: "black" }}>FFBB</span> sont
            en pause, c'est là que ça devient intéressant… place aux{" "}
            <span style={{ fontWeight: 700, color: "black" }}>tournois</span> !
            C'est le moment parfait pour venir{" "}
            <span style={{ fontWeight: 700, color: "black" }}>défier</span>{" "}
            d'autres équipes et voir si ton jeu a{" "}
            <span style={{ fontWeight: 700, color: "black" }}>progressé.</span>
          </p>
          <a href="/eventPage" style={styles.ytbBouton}>
            VOIR LE CALENDRIER
          </a>
        </div>
      ) : (
        <div
          style={{
            marginTop: isLaptop ? 150 : 0,
            display: "flex",
            flexDirection: "column",
            alignItems: isPhone ? "flex-start" : "center",
            paddingLeft: isPhone ? 18 : 0,
            paddingRight: isPhone ? 18 : 0,
          }}
        >
          <p style={styles.sectionTitles}>Nos événements</p>
          <p style={styles.sectionSubTitle}>Le calendrier</p>
          <p
            style={{
              width: isPhone ? "" : 800,
              marginTop: 35,
              textAlign: isPhone ? "start" : "center",
            }}
          >
            Les Boss du Playground, les Lady Boss, la Unsigned League, le Ball'N
            Street ou encore le Last Round : reste connecté sur les dates des
            tournois basketball les plus hypes de France !
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: isPhone ? "column" : "row",
              width: "100%",
              marginTop: 52,
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <div style={{ width: isLaptop ? "25%" : "70%" }}>
              <video controls>
                <source src={eventVideo} type="video/mp4" />
              </video>
            </div>
            {isPhone && (
              <>
                <p
                  style={{
                    width: isPhone ? "" : 800,
                    marginTop: 20,
                    textAlign: isPhone ? "start" : "center",
                  }}
                >
                  L'été, quand la{" "}
                  <span style={{ fontWeight: 700, color: "black" }}>NBA</span>{" "}
                  et la{" "}
                  <span style={{ fontWeight: 700, color: "black" }}>FFBB</span>{" "}
                  sont en pause, c'est là que ça devient intéressant… place aux{" "}
                  <span style={{ fontWeight: 700, color: "black" }}>
                    tournois
                  </span>{" "}
                  ! C'est le moment parfait pour venir{" "}
                  <span style={{ fontWeight: 700, color: "black" }}>
                    défier
                  </span>{" "}
                  d'autres équipes et voir si ton jeu a{" "}
                  <span style={{ fontWeight: 700, color: "black" }}>
                    progressé.
                  </span>
                </p>
                <a href="/eventPage" style={styles.ytbBouton}>
                  VOIR LE CALENDRIER
                </a>
              </>
            )}
          </div>
          {!isPhone && (
            <>
              <p
                style={{
                  width: isPhone ? "" : 800,
                  marginTop: 20,
                  textAlign: isPhone ? "start" : "center",
                }}
              >
                L'été, quand la{" "}
                <span style={{ fontWeight: 700, color: "black" }}>NBA</span> et
                la <span style={{ fontWeight: 700, color: "black" }}>FFBB</span>{" "}
                sont en pause, c'est là que ça devient intéressant… place aux{" "}
                <span style={{ fontWeight: 700, color: "black" }}>
                  tournois
                </span>{" "}
                ! C'est le moment parfait pour venir{" "}
                <span style={{ fontWeight: 700, color: "black" }}>défier</span>{" "}
                d'autres équipes et voir si ton jeu a{" "}
                <span style={{ fontWeight: 700, color: "black" }}>
                  progressé.
                </span>
              </p>
              <a href="/eventPage" style={styles.ytbBouton}>
                VOIR LE CALENDRIER
              </a>
            </>
          )}
        </div>
      )}

      {/* SECTION TEMOIGNAGES */}
      <div
        style={{
          overflow: "hidden",
          width: "100%",
          position: "absolute",
          zIndex: -1,
        }}
      >
        <div style={!isPhone ? styles.circle2 : styles.circle2Mobile}></div>
      </div>
      <div
        style={{
          paddingLeft: 25,
          paddingRight: 25,
          display: "flex",
          flexDirection: "column",
          marginBottom: 650,
          marginTop: isPhone ? 100 : "",
        }}
      >
        <div>
          <p style={styles.sectionTitles}>La meilleure communauté</p>
          <h2 style={styles.sectionSubTitle}>
            Ce qu'en disent nos utilisateurs ⭐
          </h2>
        </div>
        <Carousel
          responsive={responsive}
          swipeable={true}
          draggable={true}
          showDots={true}
          arrows={false}
          customButtonGroup={<ButtonGroup />}
          renderButtonGroupOutside={true}
          customDot={<CustomDot />}
        >
          <OpinionCard
            title={"L'appli qu'il fallait pour les basketteurs"}
            name={"kainepp"}
            content={
              "Vraiment un réel besoin car cette app permet de localiser les terrains extérieurs et organiser des matchs. Plus jamais se retrouver seul sur un terrain."
            }
            img={"/assets/img/gabdou.png"}
          ></OpinionCard>
          <OpinionCard
            title={"INCROYABLE !"}
            name={"GRG_"}
            content={
              "Depuis que j'ai cette application j'ai beaucoup moins de difficulté à trouver des bons terrains, je la recommande à tout le monde !"
            }
            img={"/assets/img/fahim.png"}
          ></OpinionCard>
          <OpinionCard
            title={"Très bonne application"}
            name={"Namir91"}
            content={
              "Application très sociable qui permet de lier la communautée basketball"
            }
            img={"/assets/img/fahim.png"}
          ></OpinionCard>
          <OpinionCard
            title={"L'application que j'attendais !"}
            name={"vwvwtz"}
            content={
              "Enfin une application qui te permet de trouver des terrains avec des joueurs, en plus l'option événement est top ! Je valide"
            }
            img={"/assets/img/fahim.png"}
          ></OpinionCard>
          <OpinionCard
            title={"Très utile"}
            name={"xs_plm"}
            content={
              "C'est une très bonne application qui est bien organisée, elle te permet de trouver tout les terrains de basket à proximité de chez toi et de participer à des tournois si tu joues généralement tout seul."
            }
            img={"/assets/img/anoureth.jpeg"}
          ></OpinionCard>
          <OpinionCard
            title={"Super"}
            name={"Mezcla92"}
            content={
              "L'application est vraiment top et très utile pour la communautée basketball, une grande avancée en France dans ce domaine merci !"
            }
            img={"/assets/img/fahim.png"}
          ></OpinionCard>
        </Carousel>
      </div>

      {/* PRESENTATION APP */}
      {isLaptop && (
        <div
          style={{
            overflow: "hidden",
            width: "100%",
            position: "absolute",
            zIndex: -1,
          }}
        >
          <div style={{ ...styles.circleLaptop, marginTop: 0 }}></div>
        </div>
      )}
      {isTablet && (
        <div
          style={{
            overflow: "hidden",
            width: "100%",
            position: "absolute",
            zIndex: -1,
          }}
        >
          <div style={{ ...styles.circleTablet, marginTop: 170 }}></div>
        </div>
      )}
      {isPhone && (
        <div
          style={{
            overflow: "hidden",
            width: "100%",
            position: "absolute",
            zIndex: -1,
          }}
        >
          <div style={styles.circleMobile}></div>
        </div>
      )}
      {!isPhone && !isTablet && !isLaptop && (
        <div
          style={{
            overflow: "hidden",
            width: "100%",
            position: "absolute",
            zIndex: -1,
          }}
        >
          <div style={styles.circle}></div>
        </div>
      )}
      <div style={styles.appSection}>
        {isPhone && (
          <>
            <p style={styles.sectionTitles}>Ball'N Connect sur M6</p>
            <p style={{ ...styles.sectionSubTitle, marginBottom: 30 }}>
              Nous étions à « Qui veut être mon associé »
            </p>
          </>
        )}
        {isPhone && (
          <div style={{ ...styles.columnSections, overflow: "hidden" }}>
            <StaticImage
              src="../assets/img/QVEMA.webp"
              quality={100}
              style={{
                width: "auto",
                maxWidth: isTablet ? 400 : 350,
                height: "auto",
                borderRadius: "8px",
                backgroundSize: "center",
                marginBottom: 30,
                alignSelf: "center",
              }}
              alt="photo qui veut etre mon associe"
            />
          </div>
        )}
        {isLaptop && (
          <div style={{ ...styles.columnSections, overflow: "hidden" }}>
            <StaticImage
              src="../assets/img/QVEMA.webp"
              quality={100}
              width={500}
              height={"auto"}
              style={{
                borderRadius: "8px",
                backgroundSize: "center",
              }}
              alt="photo qui veut etre mon associe"
            />
          </div>
        )}
        <div
          style={{ ...styles.columnSections, width: isPhone ? "100%" : 700 }}
        >
          {!isPhone && (
            <>
              <p style={styles.sectionTitles}>Ball'N Connect sur M6</p>
              <p
                style={{
                  ...styles.sectionSubTitle,
                  marginBottom: isLaptop ? 110 : 0,
                }}
              >
                Nous étions à « Qui veut être mon associé »
              </p>
            </>
          )}
          {isTablet && (
            <>
              <div style={styles.columnSections}>
                <StaticImage
                  src="../assets/img/QVEMA.webp"
                  quality={100}
                  style={{
                    width: "auto",
                    maxWidth: isTablet ? 400 : 350,
                    height: "auto",
                    borderRadius: "8px",
                    backgroundSize: "center",
                    marginBottom: 30,
                    alignSelf: "center",
                  }}
                  alt="photo qui veut etre mon associe"
                />
              </div>
            </>
          )}
          {isPhone ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: 50,
                marginBottom: 50,
              }}
            >
              <p>
                Ball'N Connect, le plus grand club de basketball digital au
                monde, a débarqué sur M6 dans{" "}
                <span style={{ fontWeight: 700, color: "black" }}>
                  Qui veut être mon associé, Saison 4.
                </span>
              </p>
              <p style={{ marginTop: 30 }}>
                Notre boss, Gabdou, a marqué un tournant pour le streetball,
                captivant plus de 2 millions de téléspectateurs. Devant un jury
                d'exception composé de{" "}
                <span style={{ fontWeight: 700, color: "black" }}>
                  Tony Parker
                </span>
                , ex-star{" "}
                <span style={{ fontWeight: 700, color: "black" }}>NBA </span>
                des San Antonio Spurs, d'Éric Larchevêque et de Jean-Pierre
                Nadir, fondateur d'easyvoyage.{" "}
                <span style={{ fontWeight: 700, color: "black" }}>
                  Ball'N Connect{" "}
                </span>
                s'est lancé une seule mission : écrire l'histoire.
              </p>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: 50,
              }}
            >
              <p>
                Ball'N Connect, le plus grand club de basketball digital au
                monde, a débarqué sur M6 dans{" "}
                <span style={{ fontWeight: 700, color: "black" }}>
                  Qui veut être mon associé, Saison 4.
                </span>
              </p>
              <p style={{ marginTop: 30 }}>
                Notre boss, Gabdou, a marqué un tournant pour le streetball,
                captivant plus de 2 millions de téléspectateurs. Devant un jury
                d'exception composé de{" "}
                <span style={{ fontWeight: 700, color: "black" }}>
                  Tony Parker
                </span>
                , ex-star{" "}
                <span style={{ fontWeight: 700, color: "black" }}>NBA </span>
                des San Antonio Spurs, d'Éric Larchevêque et de Jean-Pierre
                Nadir, fondateur d'easyvoyage.{" "}
                <span style={{ fontWeight: 700, color: "black" }}>
                  Ball'N Connect{" "}
                </span>
                s'est lancé une seule mission : écrire l'histoire.
              </p>
            </div>
          )}
        </div>
      </div>

      {/* PRESENTATION BALLN COACHING */}
      {isLaptop && (
        <div
          style={{
            overflow: "hidden",
            width: "100%",
            position: "absolute",
            zIndex: -1,
          }}
        >
          <div style={{ ...styles.circle3Laptop, marginTop: 200 }}></div>
        </div>
      )}
      {isTablet && (
        <div
          style={{
            overflow: "hidden",
            width: "100%",
            position: "absolute",
            zIndex: -1,
          }}
        >
          <div style={{ ...styles.circle3Tablet, marginTop: 130 }}></div>
        </div>
      )}
      {isPhone && (
        <div
          style={{
            overflow: "hidden",
            width: "100%",
            position: "absolute",
            zIndex: -1,
          }}
        >
          <div style={styles.circle3Mobile}></div>
        </div>
      )}
      {!isPhone && !isTablet && !isLaptop && (
        <div
          style={{
            overflow: "hidden",
            width: "100%",
            position: "absolute",
            zIndex: -1,
          }}
        >
          <div style={styles.circle3}></div>
        </div>
      )}
      {!isPhone ? (
        <div
          style={{
            display: "flex",
            flexDirection: isTablet ? "column" : "row",
            padding: isTablet ? "0px 18px 0px 18px" : "0px 60px 0px 95px",
            justifyContent: "space-around",
            marginTop: isTablet ? 35 : 140,
            alignItems: isTablet ? "center" : "",
          }}
        >
          {isTablet && (
            <>
              <p style={styles.sectionTitles}>Ball'N Coaching</p>
              <p style={styles.sectionSubTitle}>
                Ball'N Coaching, c'est quoi ?
              </p>
            </>
          )}
          <div style={styles.columnSections}>
            <StaticImage
              quality={100}
              src="../assets/img/bnccoaching123.webp"
              style={{
                width: 500,
                height: 500,
                borderRadius: "20px",
                marginTop: isTablet ? 50 : 150,
              }}
              alt="logo"
            />
          </div>
          <div
            style={{
              ...styles.columnSections,
              width: isLaptop ? 500 : 700,
              marginTop: 100,
            }}
          >
            {!isTablet && (
              <>
                <p style={styles.sectionTitles}>Ball'N Coaching</p>
                <p style={styles.sectionSubTitle}>
                  Ball'N Coaching, c'est quoi ?
                </p>
              </>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 30,
                marginTop: isLaptop && 50,
                marginBottom: 50,
                textAlign: "justify",
              }}
            >
              <p>
                Tu as envie de progresser dans ton{" "}
                <span style={{ fontWeight: 700, color: "black" }}>jeu</span>,
                mais tu ne sais pas trop comment t'y prendre ? C'est là que
                Ball'N Coaching entre en scène ! Cette plateforme, c'est comme
                avoir un coach personnel dans ta poche.
              </p>
              <p>
                L'idée ? Te mettre en relation avec des coachs de ouf qui vont
                t'aider à devenir meilleur que la veille. Le but est d'augmenter
                tes{" "}
                <span style={{ fontWeight: 700, color: "black" }}>
                  statistiques
                </span>{" "}
                et voir un{" "}
                <span style={{ fontWeight: 700, color: "black" }}></span>
                résultat concret, tu pourras suivre tes progrès en temps réel.
                Que tu sois en{" "}
                <span style={{ fontWeight: 700, color: "black" }}>
                  compétition{" "}
                </span>
                ou simplement pour le plaisir, Ball'N Coaching te permet de te
                préparer pour chaque{" "}
                <span style={{ fontWeight: 700, color: "black" }}>match</span>.
                Avec des conseils personnalisés et des{" "}
                <span style={{ fontWeight: 700, color: "black" }}>game </span>
                plans adaptés, tu seras prêt à affronter n'importe quel défi sur
                le terrain.
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div style={styles.appSection}>
          {isPhone && (
            <>
              <p style={styles.sectionTitles}>Ball'N Coaching</p>
              <p style={{ ...styles.sectionSubTitle, paddingRight: "15%" }}>
                Ball'N Coaching, c'est quoi ?
              </p>
            </>
          )}
          <div style={styles.columnSections}>
            <StaticImage
              quality={100}
              src="../assets/img/bnccoaching123.webp"
              style={{
                width: isPhone ? "auto" : 568,
                height: isPhone ? "auto" : 420,
                maxWidth: 350,
                borderRadius: "20px",
                marginTop: isPhone ? 0 : 150,
              }}
              alt="logo"
            />
          </div>
          <div
            style={{
              ...styles.columnSections,
              width: isPhone ? "" : 700,
              marginTop: isPhone ? "" : 100,
            }}
          >
            {!isPhone && (
              <>
                <p style={styles.sectionTitles}>Ball'N Coaching</p>
                <p style={styles.sectionSubTitle}>
                  Ball'N Coaching, c'est quoi ?
                </p>
              </>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 30,
                marginTop: 50,
                marginBottom: 50,
              }}
            >
              <p>
                Tu as envie de progresser dans ton{" "}
                <span style={{ fontWeight: 700, color: "black" }}>jeu</span>,
                mais tu ne sais pas trop comment t'y prendre ? C'est là que
                Ball'N Coaching entre en scène ! Cette plateforme, c'est comme
                avoir un coach personnel dans ta poche.
              </p>
              <p>
                L'idée ? Te mettre en relation avec des coachs de ouf qui vont
                t'aider à devenir meilleur que la veille. Le but est d'augmenter
                tes{" "}
                <span style={{ fontWeight: 700, color: "black" }}>
                  statistiques
                </span>{" "}
                et voir un{" "}
                <span style={{ fontWeight: 700, color: "black" }}></span>
                résultat concret, tu pourras suivre tes progrès en temps réel.
                Que tu sois en{" "}
                <span style={{ fontWeight: 700, color: "black" }}>
                  compétition{" "}
                </span>
                ou simplement pour le plaisir, Ball'N Coaching te permet de te
                préparer pour chaque{" "}
                <span style={{ fontWeight: 700, color: "black" }}>match</span>.
                Avec des conseils personnalisés et des{" "}
                <span style={{ fontWeight: 700, color: "black" }}>game </span>
                plans adaptés, tu seras prêt à affronter n'importe quel défi sur
                le terrain.
              </p>
            </div>
          </div>
        </div>
      )}

      <div style={{ ...styles.appSection, marginTop: 0 }}>
        {isPhone && (
          <div style={{ ...styles.columnSections, overflow: "hidden" }}>
            <StaticImage
              src="../assets/img/bncoachingimage(1).webp"
              quality={100}
              style={{
                alignSelf: "center",
                width: "100%",
                height: "auto",
                marginLeft: "auto",
                marginRight: "auto",
                borderRadius: 20,
              }}
              alt="logo"
            />
          </div>
        )}
        <div
          style={{ ...styles.columnSections, width: isPhone ? "100%" : 700 }}
        >
          {isTablet && (
            <div>
              <StaticImage
                src="../assets/img/bncoachingimage(1).webp"
                quality={100}
                style={{
                  alignSelf: "center",
                  width: 600,
                  borderRadius: 20,
                }}
                alt="logo"
              />
            </div>
          )}
          {isPhone ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 20,
                marginTop: 50,
                marginBottom: 50,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <p style={styles.bnCoachingNb}>01</p>
                <p style={{ ...styles.bnCoachingInfo, marginLeft: 35 }}>
                  {
                    <text
                      style={{
                        fontSize: "16px",
                        fontWeight: 700,
                        color: "black",
                      }}
                    >
                      Mise en relation :
                    </text>
                  }{" "}
                  On te met en relation avec un{" "}
                  <span style={{ fontWeight: 700, color: "black" }}>
                    Personal Trainer
                  </span>{" "}
                  trié avec soin près de chez toi. Tu n'as plus qu'à
                  t'entraîner.
                </p>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <p style={styles.bnCoachingNb}>02</p>
                <p style={{ ...styles.bnCoachingInfo, marginLeft: 28 }}>
                  {
                    <text
                      style={{
                        fontSize: "16px",
                        fontWeight: 700,
                        color: "black",
                      }}
                    >
                      Services personnalisés :
                    </text>
                  }{" "}
                  Bénéficie de services personnalisés : shooting photo, vidéo,
                  personal branding, etc.
                </p>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <p style={styles.bnCoachingNb}>03</p>
                <p
                  style={{
                    ...styles.bnCoachingInfo,
                    marginLeft: 28,
                    marginBottom: 40,
                  }}
                >
                  {
                    <text
                      style={{
                        fontSize: "16px",
                        fontWeight: 700,
                        color: "black",
                      }}
                    >
                      Communauté :
                    </text>
                  }{" "}
                  Fais partie de la communauté{" "}
                  <span style={{ fontWeight: 700, color: "black" }}>
                    basketball
                  </span>{" "}
                  Ball'N Connect, qui avance et progresse ensemble.
                </p>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 60,
                marginTop: 50,
                marginBottom: 50,
                // width: 400
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: isPhone ? 55 : 42,
                  textAlign: "justify",
                }}
              >
                <p style={styles.bnCoachingNb}>01</p>
                <p style={{ ...styles.bnCoachingInfo, marginLeft: 35 }}>
                  {
                    <text
                      style={{
                        fontSize: "16px",
                        fontWeight: 700,
                        color: "black",
                      }}
                    >
                      Mise en relation :
                    </text>
                  }{" "}
                  On te met en relation avec un{" "}
                  <span style={{ fontWeight: 700, color: "black" }}>
                    Personal Trainer
                  </span>{" "}
                  trié avec soin près de chez toi. Tu n'as plus qu'à
                  t'entraîner.
                </p>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <p style={styles.bnCoachingNb}>02</p>
                <p style={{ ...styles.bnCoachingInfo, marginLeft: 28 }}>
                  {
                    <text
                      style={{
                        fontSize: "16px",
                        fontWeight: 700,
                        color: "black",
                      }}
                    >
                      Services personnalisés :
                    </text>
                  }{" "}
                  Bénéficie de services personnalisés : shooting photo, vidéo,
                  personal branding, etc.
                </p>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <p style={styles.bnCoachingNb}>03</p>
                <p
                  style={{
                    ...styles.bnCoachingInfo,
                    marginLeft: 28,
                    marginBottom: 40,
                  }}
                >
                  {
                    <text
                      style={{
                        fontSize: "16px",
                        fontWeight: 700,
                        color: "black",
                      }}
                    >
                      Communauté :
                    </text>
                  }{" "}
                  Fais partie de la communauté{" "}
                  <span style={{ fontWeight: 700, color: "black" }}>
                    basketball
                  </span>{" "}
                  Ball'N Connect, qui avance et progresse ensemble.
                </p>
              </div>
            </div>
          )}
        </div>
        {isLaptop && (
          <div style={{ ...styles.columnSections, overflow: "hidden" }}>
            <StaticImage
              src="../assets/img/bncoachingimage(1).webp"
              quality={100}
              style={{
                alignSelf: "center",
                width: "auto",
                maxWidth: 600,
                height: "auto",
                borderRadius: 20,
                marginTop: 50,
              }}
              alt="logo"
            />
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: -50,
          paddingLeft: 18,
          paddingRight: 18,
        }}
      >
        <a href="https://ballncoaching.com/" style={styles.ytbBouton}>
          Démarrer ma progression
        </a>
      </div>

      {/* SECTION EQUIPE */}
      <div
        style={{
          paddingLeft: 18,
          paddingRight: 18,
          display: "flex",
          flexDirection: "column",
          marginTop: 50,
        }}
      >
        <div>
          <p style={styles.sectionTitles}>Qui sommes-nous ?</p>
          <h2 style={styles.sectionSubTitle}>Les fondateurs</h2>
        </div>
        {/* <Carousel
          responsive={responsiveTeam}
          swipeable={true}
          draggable={true}
          showDots={true}
          arrows={false}
          customDot={<CustomDot />}
        >
          <TeamCard
            name={"Gabdou BAROA"}
            poste={"Fondateur & CEO"}
            img={gabdou}
            linkedin={
              "https://www.linkedin.com/in/aurelien-gabdou-baroa-b7a65930/"
            }
          ></TeamCard>
          <TeamCard
            name={"Anoureth PONGRATTANA"}
            poste={"Co-Fondateur & CTO"}
            img={anoureth}
            linkedin={"https://www.linkedin.com/in/anoureth-pongrattana/"}
          ></TeamCard>
        </Carousel> */}
      </div>

      {/* SECTION BLOG */}
      {!isPhone && !isTablet ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
            paddingLeft: 18,
          }}
        >
          <p style={styles.sectionTitles}>Notre blog</p>
          <h2 style={styles.sectionSubTitle}>
            Les derniers articles à ne pas manquer
          </h2>
          <p
            style={{
              width: 800,
              textAlign: "start",
              marginTop: 20,
            }}
          >
            Explore nos articles dédiés au basketball : plonge dans des
            rencontres captivantes, découvre les stars des playgrounds et prote
            de conseils pour perfectionner ton jeu.
          </p>
          {/* <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              marginTop: 48,
              justifyContent: "space-around",
              paddingLeft: "25px",
              paddingRight: "25px",
            }}
          >
            {posts.map((post) => (
              <ArticleCard
                key={post.id}
                img={post.frontmatter.image}
                title={post.frontmatter.title}
                date={post.frontmatter.date}
                content={post.excerpt}
                author={post.frontmatter.author}
                link={post.frontmatter.slug}
              ></ArticleCard>
            ))}
          </div> */}
          <a href="/blog" style={styles.ytbBouton}>
            VOIR LE BLOG
          </a>
        </div>
      ) : (
        <div
          style={{
            paddingLeft: 18,
            paddingRight: 18,
            display: "flex",
            flexDirection: "column",
            marginTop: 50,
          }}
        >
          <p
            style={{
              ...styles.sectionTitles,
              textAlign: isTablet ? "center" : "start",
            }}
          >
            Notre blog
          </p>
          <h2
            style={{
              ...styles.sectionSubTitle,
              textAlign: isTablet ? "center" : "start",
            }}
          >
            Les derniers articles à ne pas manquer
          </h2>
          <p
            style={{
              marginBottom: 48,
              textAlign: isTablet ? "center" : "start",
              marginTop: 15,
            }}
          >
            Explore nos articles dédiés au basketball : plonge dans des
            rencontres captivantes, découvre les stars des playgrounds et prote
            de conseils pour perfectionner ton jeu.
          </p>

          {/* <Carousel
            responsive={responsive}
            swipeable={true}
            draggable={true}
            showDots={true}
            arrows={false}
            customButtonGroup={<ButtonGroup />}
            renderButtonGroupOutside={true}
            customDot={<CustomDot />}
          >
            {posts.map((post) => (
              <ArticleCard
                key={post.id}
                img={post.frontmatter.image}
                title={post.frontmatter.title}
                date={post.frontmatter.date}
                content={post.excerpt}
                author={post.frontmatter.author}
                link={post.frontmatter.slug}
              ></ArticleCard>
            ))}
          </Carousel> */}
          <a
            href="/blog"
            style={{
              ...styles.ytbBouton,
              marginTop: 550,
              alignSelf: "center",
              marginBottom: 150,
            }}
          >
            VOIR LE BLOG
          </a>
        </div>
      )}
      <div id="faq"></div>
      {/* SECTION FAQ */}
      <div
        style={{
          paddingLeft: 18,
          paddingRight: 18,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div>
          <p style={styles.sectionTitles}>FAQ</p>
          <h2 style={styles.sectionSubTitle}>Questions / Réponses</h2>
          <div
            style={{
              display: "flex",
              flexDirection: !isPhone && !isTablet ? "row" : "column",
            }}
          >
            <div
              style={{
                marginTop: isLaptop ? 0 : 20,
                marginBottom: 15,
                marginRight: !isPhone && !isTablet ? 10 : 0,
              }}
            >
              <p style={{ marginBottom: 15, ...styles.sectionTitles }}>
                Les questions les plus fréquentes :
              </p>
              <Accordion
                sx={{
                  "& .MuiTypography-root": {
                    fontFamily: "Montserrat",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography style={{ fontWeight: 600 }}>
                    Ball'N Connect, c'est quoi ?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Notre application est une plateforme sociale pour les
                    joueurs de basket, regroupant plus de 50 000 utilisateurs.
                    Elle permet de connecter la communauté fan de basket autour
                    de 3 grands axes :
                    <br />
                    <br />● Find : Géolocalise les terrains autour de toi et les
                    utilisateurs de l'application pour savoir sur quel
                    playground vous réunir. <br />● Play : Organise tes matchs
                    et tournois directement via notre application. Tu peux
                    également réserver des terrains dans certaines salles
                    payantes.
                    <br /> ● Progress : Mise en relation directe avec des coachs
                    de basket. Plus tu fréquenteras nos playgrounds, plus tu
                    gagneras en compétences !
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  "& .MuiTypography-root": {
                    fontFamily: "Montserrat",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography style={{ fontWeight: 600 }}>
                    Dois-je être licencié dans un club de basketball ?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Il n'y a aucune obligation de licence. Notre application a
                    pour objectif de développer et de faciliter la pratique du
                    basket en la rendant accessible à tous.
                    <br />
                    Rejoins dès maintenant notre communauté !
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  "& .MuiTypography-root": {
                    fontFamily: "Montserrat",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography style={{ fontWeight: 600 }}>
                    L'application Ball'N Connect ?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Vous pouvez télécharger l'application Ball'N Connect sur le
                    Google Play Store pour les appareils Android et sur l'App
                    Store d'Apple pour les appareils iOS. L'application est
                    gratuite et offre une interface conviviale pour tous les
                    passionnés de basketball.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  "& .MuiTypography-root": {
                    fontFamily: "Montserrat",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4a-content"
                  id="panel4a-header"
                >
                  <Typography style={{ fontWeight: 600 }}>
                    L'application est-elle payante ?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    L'application est gratuite et offre une interface accessible
                    à tous. Une expérience plus complète sera bientôt disponible
                    grâce à un service Premium.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  "& .MuiTypography-root": {
                    fontFamily: "Montserrat",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel5a-content"
                  id="panel5a-header"
                >
                  <Typography style={{ fontWeight: 600 }}>
                    À qui s'adresse l'application ?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Ball'N Connect s'adresse à toute la communauté basket, sans
                    distinction, en offrant un espace où chacun peut se
                    retrouver et partager sa passion.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  "& .MuiTypography-root": {
                    fontFamily: "Montserrat",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel6a-content"
                  id="panel6a-header"
                >
                  <Typography style={{ fontWeight: 600 }}>
                    Comment créer mon compte ?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Télécharge l'application sur ton Store, puis crée ton prol
                    en te connectant via un compte Facebook, Apple ou une
                    adresse mail. Ensuite, complète les informations de ton prol
                    et commence à déer les joueurs proches de chez toi !
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  "& .MuiTypography-root": {
                    fontFamily: "Montserrat",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel7a-content"
                  id="panel7a-header"
                >
                  <Typography style={{ fontWeight: 600 }}>
                    Quels niveaux de jeu sont disponibles sur l'application ?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Du débutant au All-Star, Ball'N Connect accueille tous les
                    joueurs, sans exception. Enle ta meilleure paire et
                    rejoins-nous sur les playgrounds !
                  </Typography>
                </AccordionDetails>
              </Accordion>
              {!isPhone && !isTablet && (
                <>
                  <p
                    style={{
                      ...styles.sectionTitles,
                      marginTop: 20,
                      marginBottom: 15,
                    }}
                  >
                    Collaboration :
                  </p>
                  <Accordion
                    sx={{
                      "& .MuiTypography-root": {
                        fontFamily: "Montserrat",
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel8a-content"
                      id="panel8a-header"
                    >
                      <Typography style={{ fontWeight: 600 }}>
                        Comment mon entreprise peut-elle soutenir Ball'N Connect
                        ?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        L'équipe de Ball'N Connect est ouverte à toutes vos
                        propositions de collaboration. Nous serions ravis de
                        travailler ensemble pour promouvoir la communauté basket
                        et rendre ce sport encore plus accessible à tous.
                        <br />
                        Pour discuter de partenariats ou d'opportunités de
                        soutien, n'hésitez pas à nous contacter directement sur
                        nos réseaux sociaux ou via notre formulaire de contact,
                        rubrique « nous contacter ».
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </>
              )}
            </div>
            <div
              style={{
                marginTop: isLaptop ? 0 : 20,
                marginBottom: 10,
                marginLeft: !isPhone && !isTablet ? 10 : 0,
              }}
            >
              <p style={{ marginBottom: 15, ...styles.sectionTitles }}>
                Les questions de la communauté :
              </p>
              <Accordion
                sx={{
                  "& .MuiTypography-root": {
                    fontFamily: "Montserrat",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel9a-content"
                  id="panel9a-header"
                >
                  <Typography style={{ fontWeight: 600 }}>
                    Où trouver des playgrounds près de chez moi ?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Ouvre simplement l'application et autorise l'accès à ta
                    localisation. Une fois activé, explore la carte pour
                    découvrir tous les playgrounds autour de toi !
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  "& .MuiTypography-root": {
                    fontFamily: "Montserrat",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel10a-content"
                  id="panel10a-header"
                >
                  <Typography style={{ fontWeight: 600 }}>
                    Où sont référencés les terrains ?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Ball'N Connect te permet de trouver un terrain partout, où
                    que tu sois. Nous comptons déjà plus de 4 000 terrains
                    référencés à travers le monde. Et si le playground près de
                    chez toi n'est pas encore dans l'application, tu peux
                    l'ajouter toi-même !
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  "& .MuiTypography-root": {
                    fontFamily: "Montserrat",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel11a-content"
                  id="panel11a-header"
                >
                  <Typography style={{ fontWeight: 600 }}>
                    Comment ajouter un terrain sur l'application Ball'N Connect
                    ?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Pour ajouter un terrain, suis ces étapes :
                    <br />
                    <br />
                    1. Appuie sur le bouton “+” en bas de l'application, puis
                    sélectionne “Ajouter terrain”. <br />
                    2. Renseigne les spécicités du terrain : type de paniers,
                    revêtement du sol, etc.
                    <br /> 3. Ajoute des photos du terrain pour compléter les
                    informations.
                    <br />
                    <br />
                    Et voilà, le terrain sera visible par toute la communauté !
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  "& .MuiTypography-root": {
                    fontFamily: "Montserrat",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel12a-content"
                  id="panel12a-header"
                >
                  <Typography style={{ fontWeight: 600 }}>
                    Quels types de terrains peuvent être ajoutés sur
                    l'application Ball'N Connect ?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Tous les terrains, sans exception, peuvent être intégrés à
                    notre application, sans conditions particulières. Une fois
                    soumis, notre équipe de modération valide le nouveau
                    playground, et il est ensuite visible par toute la
                    communauté dans l'application.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  "& .MuiTypography-root": {
                    fontFamily: "Montserrat",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel13a-content"
                  id="panel13a-header"
                >
                  <Typography style={{ fontWeight: 600 }}>
                    Comment créer un match depuis l'application Ball'N Connect ?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Pour créer un match, suis ces étapes :
                    <br />
                    <br />
                    1. Appuie sur le bouton “+” en bas de l'application, puis
                    sélectionne “Ajouter match”. <br />
                    2. Renseigne les détails du match : lieu, date et heure.
                    <br /> 3. Ajoute des spécications comme le format du match,
                    l'ambiance souhaitée ou le niveau de jeu.
                    <br />
                    4. Invite la communauté à te rejoindre et prépare-toi à
                    jouer !
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  "& .MuiTypography-root": {
                    fontFamily: "Montserrat",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel14a-content"
                  id="panel14a-header"
                >
                  <Typography style={{ fontWeight: 600 }}>
                    Comment rejoindre un match sur l'application Ball'N Connect
                    ?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Depuis l'accueil de l'application, accède à la liste des
                    matchs disponibles. Consulte les détails du match (lieu,
                    horaire, niveau de jeu, etc.), puis clique sur “Participer”
                    pour te joindre au match. C'est aussi simple que ça !
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  "& .MuiTypography-root": {
                    fontFamily: "Montserrat",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel15a-content"
                  id="panel15a-header"
                >
                  <Typography style={{ fontWeight: 600 }}>
                    Comment entrer en contact avec les membres inscrits à un
                    match ?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Si tu t'es inscrit à un match mais que tu n'es plus
                    disponible, pas de problème ! Tu peux retirer ta
                    participation directement via l'application. Pour
                    communiquer avec les autres participants, utilise le chat
                    intégré à l'événement, accessible depuis la page du match.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
          {(isTablet || isPhone) && (
            <div
              style={{
                marginTop: isLaptop ? 0 : 20,
                marginBottom: 15,
              }}
            >
              <p style={{ marginBottom: 15, ...styles.sectionTitles }}>
                Collaboration :
              </p>
              <Accordion
                sx={{
                  "& .MuiTypography-root": {
                    fontFamily: "Montserrat",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel16a-content"
                  id="panel16a-header"
                >
                  <Typography style={{ fontWeight: 600 }}>
                    Comment mon entreprise peut-elle soutenir Ball'N Connect ?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    L'équipe de Ball'N Connect est ouverte à toutes vos
                    propositions de collaboration. Nous serions ravis de
                    travailler ensemble pour promouvoir la communauté basket et
                    rendre ce sport encore plus accessible à tous.
                    <br />
                    Pour discuter de partenariats ou d'opportunités de soutien,
                    n'hésitez pas à nous contacter directement sur nos réseaux
                    sociaux ou via notre formulaire de contact, rubrique « nous
                    contacter ».
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          )}
        </div>
      </div>

      {/* FOOTER*/}
      <Footer id="contact"></Footer>
    </div>
  );
}

export const Head = () => <SEO />;

// export const pageQuery = graphql`
//   query HomeQuery {
//     blog: allMarkdownRemark(
//       sort: { fields: [frontmatter___date], order: DESC }
//       limit: 3
//     ) {
//       posts: nodes {
//         frontmatter {
//           date(fromNow: true, locale: "FR")
//           title
//           author
//           slug
//           image
//         }
//         excerpt(pruneLength: 400)
//         id
//       }
//     }
//   }
// `;
